import React from "react";

function Iphone() {
  return (
    <div className="Iphone">
      {" "}
      <section>
        <article className="blog-title">
          <h3> Iphone Folder Structure </h3>
          <p>
            {" "}
            Last updated <b>09/16/2022 </b>
          </p>
        </article>
      </section>
      <article className="blog-title">
        <p> Best way to organize phone </p>
      </article>
      <article>
        <ul>
          {" "}
          <li> Health</li>
          <li> Planners </li>
          <li> Shop </li>
          <li> Consign </li>
          <li> Travel </li>
          <li> Food </li>
          <li> Payment </li>
          <li> Education </li>
          <li> Music </li>
          <li> Education </li>
          <li> Career </li>
          <li> Social </li>
          <li> Tips </li>
          <li> Apple </li>
          <li> Google </li>
          <li>
            oragnize photos, unsubscribe from emai chains, organize apps into
            folders{" "}
          </li>
        </ul>
      </article>
    </div>
  );
}

export default Iphone;
